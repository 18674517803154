/**
 * Login Page
 */

// Core imports
import React from 'react';
import PropTypes from 'prop-types';

// Style, SEO and settings
import Helmet from 'react-helmet';
import {
  Col,
  css,
  Grid,
  Row,
  up,
  styled,
  withTheme,
  th,
} from '@smooth-ui/core-sc';

// Additional Components/Containers
import LoadAsync from '_platform/src/utils/LoadAsync';
import PublicComponent from '_platform/src/utils/PublicComponent';
import getReturnUrlPath from '_platform/src/utils/getReturnUrlPath';

import { withSettings } from 'containers/WebApp/SettingsContext';
import bgImage1x from './images/AnchorPlus-Desktop-LoginBG@1x.png';
import bgImage2x from './images/AnchorPlus-Desktop-LoginBG@2x.png';
import bgMobile1x from './images/AnchorPlus-Mobile-LoginBG@1x.png';
import bgMobile2x from './images/AnchorPlus-Mobile-LoginBG@2x.png';
import logo from '../../theme/Login-Logo@2x.png';

const LoginProvider = LoadAsync(() =>
  import(
    /* webpackChunkName: "loginProvider" */ '_platform/src/containers/LoginProvider/LoginProvider'
  )
);
const LoginForm = LoadAsync(() =>
  import(
    /* webpackChunkName: "loginForm" */ '_platform/src/components/LoginForm/LoginForm'
  )
);

const PageContainer = styled.div`
  align-items: center;
  background-color: ${th('primary')};
  background-image: url(${bgMobile1x});
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  flex: 1 0 auto;
  flex-direction: column;
  justify-content: center;
  @media only screen and (min-device-pixel-ratio: 1.5),
    only screen and (min-resolution: 144dpi),
    only screen and (min-resolution: 1.5dppx) {
    background-color: #131d28;
    background-image: url(${bgMobile2x});
  }

  ${up(
    'md',
    css`
      background-image: url(${bgImage1x});
      @media only screen and (min-device-pixel-ratio: 1.5),
        only screen and (min-resolution: 144dpi),
        only screen and (min-resolution: 1.5dppx) {
        background-image: url(${bgImage2x});
        background-color: ${th('primary')};
      }
    `
  )}
`;

const LoginLogo = styled.h1`
  margin-bottom: 0;
  margin-top: 0;
  padding-bottom: 1rem;
  padding-top: 0;
  img {
    height: auto;
    width: 300px;
  }
`;

const IntroContainer = styled.div`
  background-color: rgba(0, 0, 0, 0.5);
  box-sizing: border-box;
  color: #fff;
  margin-bottom: 2rem;
  margin-top: 2rem;
  padding: 2rem;

  ${up(
    'md',
    css`
      margin-bottom: 5rem;
      margin-top: 5rem;
    `
  )}

  h2 {
    font-size: 30px;
  }
  p {
    color: #fff;
  }
  a {
    color: #fff;
    &:visited {
      color: #fff;
    }
  }
`;

const LoginContainer = styled.div`
  max-width: 500px;

  .reset-password {
    a {
      font-size: 14px;
      font-weight: normal;
    }
  }

  ${up(
    'lg',
    css`
      padding-left: 2rem;
      min-width: 300px;
    `
  )};
`;

const LoginPage = ({ settings }) => (
  <PublicComponent
    redirectOnError={
      // Redirect to returnUrl, if it's not the same as loginPagePath
      (getReturnUrlPath !== settings.settingsApp.loginPagePath &&
        getReturnUrlPath) ||
      '/'
    }
  >
    <Helmet>
      <title>Login</title>
      {/*<meta name="description" content="Page description" />*/}
    </Helmet>

    <PageContainer>
      <Grid className="page-container">
        <IntroContainer>
          <Grid>
            <Row>
              <Col xs={12} sm={10} osm={1} lg={6} olg={0}>
                <LoginLogo className="text--center">
                  <img
                    src={logo}
                    alt={
                      settings.settingsApp && settings.settingsApp.siteName
                        ? settings.settingsApp.siteName
                        : 'ProgramName'
                    }
                  />
                </LoginLogo>
                <h2 className="text--primary">
                  Welcome to Anchor Partners Plus
                </h2>
                <p className="spacer--bottom--small text--center">
                  The Anchor Partners Plus program, bought to you by Anchor™
                  Food Professional, rewards distributors for their ongoing
                  support of Anchor™ Food Professional products. To be invited
                  to participate contact your Anchor™ Food Professional Account
                  Manager.
                </p>
              </Col>
              <Col xs={12} sm={10} osm={1} lg={6} olg={0}>
                <LoginContainer>
                  <h2>LOGIN</h2>
                  <p>
                    Login with the username and password you have been emailed.
                  </p>
                  <LoginProvider disableMeta>
                    <LoginForm formLabels={{ labelUserName: 'Username' }} />
                  </LoginProvider>
                </LoginContainer>
              </Col>
            </Row>
          </Grid>
        </IntroContainer>
      </Grid>
    </PageContainer>
  </PublicComponent>
);

LoginPage.propTypes = {
  settings: PropTypes.object.isRequired,
};

export default withTheme(withSettings(LoginPage));
